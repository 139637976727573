import { Link, graphql } from 'gatsby';
import React, { FC } from 'react';

import { ExamplesQuery } from './__generated__/ExamplesQuery';

const Examples: FC<{ data: ExamplesQuery }> = ({ data }) => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold">Examples</h1>
      <ul>
        {data.allSitePage.nodes
          .sort((a, b) => (a.path > b.path ? 1 : -1))
          .map((node) => (
            <li key={node.path}>
              <Link to={node.path}>{node.path}</Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export const query = graphql`
  query ExamplesQuery {
    allSitePage(filter: { component: { regex: "/.example.tsx?$/" } }) {
      nodes {
        path
      }
    }
  }
`;

export default Examples;
